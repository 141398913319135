var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "mega-header",
        {
          staticClass: "bg-lighter",
          attrs: { title: _vm.$t("create_reports") }
        },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("create_reports_desc")))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("mega-input", {
            attrs: {
              label: _vm.$t("date.interval.year"),
              valid: _vm.year.length > 0
            },
            model: {
              value: _vm.year,
              callback: function($$v) {
                _vm.year = $$v
              },
              expression: "year"
            }
          }),
          _vm._v(" "),
          _c("mega-select", {
            attrs: {
              label: _vm.$t("date.interval.month"),
              placeholder: _vm.$t("date.interval.month"),
              valid: _vm.month !== undefined,
              options: _vm.months
            },
            model: {
              value: _vm.month,
              callback: function($$v) {
                _vm.month = $$v
              },
              expression: "month"
            }
          }),
          _vm._v(" "),
          _c("mega-input", {
            attrs: { label: _vm.$t("income"), valid: _vm.income.length > 0 },
            model: {
              value: _vm.income,
              callback: function($$v) {
                _vm.income = $$v
              },
              expression: "income"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "navbar" }, [
          _c(
            "div",
            { staticClass: "navbar-group ml-auto" },
            [
              _c(
                "mega-button",
                {
                  staticClass: "btn-white mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "btn-primary mr-2",
                  attrs: {
                    disabled:
                      !_vm.month ||
                      _vm.year.length !== 4 ||
                      _vm.income.length === 0
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }