<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    @onClose="modal = false"
  >
    <mega-header :title="$t('create_reports')" class="bg-lighter">
      <span class="text-muted">{{ $t("create_reports_desc") }}</span>
    </mega-header>

    <div class="card-body">
      <mega-input
        :label="$t('date.interval.year')"
        :valid="year.length > 0"
        v-model="year"
      />

      <mega-select
        :label="$t('date.interval.month')"
        :placeholder="$t('date.interval.month')"
        v-model="month"
        :valid="month !== undefined"
        :options="months"
      />

      <mega-input
        :label="$t('income')"
        :valid="income.length > 0"
        v-model="income"
      />
    </div>

    <div class="card-body">
      <div class="navbar">
        <div class="navbar-group ml-auto">
          <mega-button class="btn-white mr-2" @click="modal = false">{{
            $t("close")
          }}</mega-button>
          <mega-button
            class="btn-primary mr-2"
            :disabled="!month || year.length !== 4 || income.length === 0"
            @click="submit"
            >{{ $t("save") }}</mega-button
          >
        </div>
      </div>
    </div>
  </mega-modal>
</template>

<script>
import Months from "../../../../data/months.json";

export default {
  data() {
    return {
      modal: false,
      month: undefined,
      year: "",
      income: "",
      months: Months
    };
  },
  methods: {
    submit() {
      this.$api.v2
        .put("/finance/reports", {
          month: Number(this.month),
          year: Number(this.year),
          income: this.income
        })
        .then(() => {
          this.modal = false;
          window.location.reload();
        });
    },
    open() {
      this.modal = true;
    }
  }
};
</script>
